import * as ActAdminSdk from '@privatix/act-admin-sdk-js'
import { getAuth } from 'firebase/auth'

let userApi = null
let purchasesApi = null
let activationsApi = null
let mappingApi = null
let financesApi = null
let resellersApi = null

class ActAdminApi {
  constructor() {
    this.defaultClient = ActAdminSdk.ApiClient.instance
    this.defaultClient.basePath = process.env.VUE_APP_API_BASE_PATH
  }

  async updateFbToken() {
    const auth = getAuth()
    if (auth.currentUser) {
      this.defaultClient.authentications.jwtAuth.accessToken = await auth.currentUser.getIdToken()
    }
  }

  async userApi() {
    if (!userApi) {
      userApi = new ActAdminSdk.UserApi()
    }

    return userApi
  }

  async purchasesApi() {
    if (!purchasesApi) {
      purchasesApi = new ActAdminSdk.PurchasesApi()
    }

    return purchasesApi
  }

  async activationsApi() {
    if (!activationsApi) {
      activationsApi = new ActAdminSdk.ActivationsApi()
    }

    return activationsApi
  }

  async mappingApi() {
    if (!mappingApi) {
      mappingApi = new ActAdminSdk.MappingApi()
    }

    return mappingApi
  }

  async financesApi() {
    if (!financesApi) {
      financesApi = new ActAdminSdk.FinancesApi()
    }

    return financesApi
  }
  
  async resellersApi() {
    if (!resellersApi) {
      resellersApi = new ActAdminSdk.ResellersApi()
    }

    return resellersApi
  }
}

export default ActAdminApi

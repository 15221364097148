<template>
  <div>
    <SearchForm @searchUser="searchUser" @searchReset="searchReset" />

    <div v-if="searchAccount">
      <UserInfo :account="searchAccount.user" />
      <Balance v-if="searchAccount.user.status !== 'deleted'" :account="searchAccount.user" />
      <div>
        <b-tabs content-class="mt-3">
          <b-tab title="Activations" active><Activations /></b-tab>
          <b-tab title="Purchases"><Purchases /></b-tab>
        </b-tabs>
      </div>
    </div>
    <div v-if="searchData && searchData.length > 1">
      <AccountsList :accounts="searchData" />
    </div>
  </div>
</template>

<script>
import { useSearch } from '@/use/search'
import SearchForm from '@/components/SearchForm'
import UserInfo from '@/components/UserInfo'
import Balance from '@/components/Balance'
import Purchases from '@/components/Purchases'
import Activations from '@/components/Activations'
import AccountsList from '@/components/AccountsList'

export default {
  components: { SearchForm, UserInfo, Balance, Purchases, AccountsList, Activations },
  computed: {
    searchData() {
      return this.$store.state.search.searchData
    },
    searchAccount() {
      return this.$store.state.search.searchAccount
    }
  },
  setup() {
    const { getSearchData } = useSearch()
    return { getSearchData }
  },
  methods: {
    async searchUser(uid, email, orderId, activationData) {
      if (!uid && !email && !orderId && !activationData) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Please input UserId, Email, OrderId, ActivationId or Number',
          timeout: 2000,
          name: 'empty_search_warning'
        })
        return
      }
      this.$store.commit('loaders/setSearchLoading', true)
      await this.getSearchData(uid, email, orderId, activationData)
      this.$store.commit('loaders/setSearchLoading', false)
    },
    searchReset() {
      this.$store.commit('search/setSearchData', null)
    }
  }
}
</script>

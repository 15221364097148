export const feedbackReasons = [
  {
    name: 'SMS is not coming',
    text: 'SMS is not coming',
    id: 1
  },
  {
    name: 'Wrong SMS received',
    text: 'Wrong SMS received',
    id: 2
  },
  {
    name: 'Number not accepted',
    text: 'Number not accepted',
    id: 3
  },
  {
    name: 'Number has been used before',
    text: 'Number has been used before',
    id: 4
  },
  {
    name: 'Blocked after activation',
    text: 'Blocked after activation',
    id: 5
  },
  {
    name: 'Other reason',
    text: 'Other reason',
    id: 6
  }
]

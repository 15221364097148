import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'
import Home from '@/views/Home'
import MappingServices from '@/views/Mapping/Services'
import ServicesProviders from '@/views/Mapping/ServicesProviders'
import ServicesProvidersImport from '@/views/Mapping/ServicesProvidersImport'
import CountriesProviders from '@/views/Mapping/CountriesProviders'
import CountriesProvidersImport from '@/views/Mapping/CountriesProvidersImport'
import Finances from '@/views/Finances'
import ResellersPayouts from '@/views/ResellersPayouts'
import Login from '@/views/Login'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      layout: 'DefaultLayout',
      requiresAuth: true,
      pageMode: 'support'
    }
  },
  {
    path: '/mapping',
    name: 'Mapping',
    component: ServicesProviders,
    meta: {
      layout: 'DefaultLayout',
      requiresAuth: true,
      pageMode: 'mapping'
    }
  },
  {
    path: '/mapping/import',
    name: 'ServicesProvidersImport',
    component: ServicesProvidersImport,
    meta: {
      layout: 'DefaultLayout',
      requiresAuth: true,
      pageMode: 'mapping'
    }
  },
  {
    path: '/mapping/countries-providers',
    name: 'CountriesProviders',
    component: CountriesProviders,
    meta: {
      layout: 'DefaultLayout',
      requiresAuth: true,
      pageMode: 'mapping'
    }
  },
  {
    path: '/mapping/countries-providers/import',
    name: 'CountriesProvidersImport',
    component: CountriesProvidersImport,
    meta: {
      layout: 'DefaultLayout',
      requiresAuth: true,
      pageMode: 'mapping'
    }
  },
  {
    path: '/mapping/services',
    name: 'MappingServices',
    component: MappingServices,
    meta: {
      layout: 'DefaultLayout',
      requiresAuth: true,
      pageMode: 'mapping'
    }
  },
  {
    path: '/finances',
    name: 'Finances',
    component: Finances,
    meta: {
      layout: 'DefaultLayout',
      requiresAuth: true,
      pageMode: 'finances'
    }
  },
  {
    path: '/resellers-payouts',
    name: 'ResellersPayouts',
    component: ResellersPayouts,
    meta: {
      layout: 'DefaultLayout',
      requiresAuth: true,
      pageMode: 'resellersPayouts'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'EmptyLayout'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const auth = getAuth()
  const currentUser = auth.currentUser

  if (requiresAuth && !currentUser) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router

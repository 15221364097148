import { reactive, toRefs } from 'vue'
import ActAdminApi from '@/helpers/actAdminApi'

export function useActAdminApi() {
  const actApiInst = new ActAdminApi()
  const actApiInstances = reactive({
    actUserApi: null,
    actPurchasesApi: null,
    actActivationsApi: null,
    actMappingApi: null,
    actFinancesApi: null,
    actResellersApi: null
  })

  const initApiInst = async () => {
    await getUserApi()
    await getPurchasesApi()
    await getActivationsApi()
    await getMappingApi()
    await getFinancesApi()
    await getResellersApi()
    await actApiInst.updateFbToken()
  }

  const getUserApi = async () => {
    if (actApiInst) {
      actApiInstances.actUserApi = await actApiInst.userApi()
    }
  }

  const getPurchasesApi = async () => {
    if (actApiInst) {
      actApiInstances.actPurchasesApi = await actApiInst.purchasesApi()
    }
  }

  const getActivationsApi = async () => {
    if (actApiInst) {
      actApiInstances.actActivationsApi = await actApiInst.activationsApi()
    }
  }

  const getMappingApi = async () => {
    if (actApiInst) {
      actApiInstances.actMappingApi = await actApiInst.mappingApi()
    }
  }

  const getFinancesApi = async () => {
    if (actApiInst) {
      actApiInstances.actFinancesApi = await actApiInst.financesApi()
    }
  }
  
  const getResellersApi = async () => {
    if (actApiInst) {
      actApiInstances.actResellersApi = await actApiInst.resellersApi()
    }
  }

  return {
    initApiInst,
    ...toRefs(actApiInstances)
  }
}

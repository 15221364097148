import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'

export function useMapping() {
  const { initApiInst, actMappingApi } = useActAdminApi()
  const { handleCommonErrors } = useErrorHandler()

  const getProviders = async (withCurrency = false) => {
    const providers = [{ value: 0, text: 'All' }]
    try {
      await initApiInst()
      const providersResp = await actMappingApi.value.getProviders()

      if (providersResp && providersResp.length > 0) {
        for (let i = 0; i < providersResp.length; i++) {
          const provider = providersResp[i]
          providers.push({
            value: provider.id,
            text: `${withCurrency ? provider.name + ' (' + provider.currency + ')' : provider.name}`
          })
        }
      }
    } catch (e) {
      handleCommonErrors(e)
    }

    return providers
  }

  const getServicesIdsNames = async () => {
    let servicesIdsNames = []
    try {
      const cachedServicesIdsNames = localStorage.getItem('servicesIdsNames')
      if (cachedServicesIdsNames) {
        servicesIdsNames = JSON.parse(cachedServicesIdsNames)
      } else {
        await initApiInst()
        servicesIdsNames = await actMappingApi.value.getAllServicesIdsNames()
        localStorage.setItem('servicesIdsNames', JSON.stringify(servicesIdsNames))
      }
    } catch (e) {
      handleCommonErrors(e)
    }

    return servicesIdsNames
  }

  const updateServicesProviders = async (items) => {
    try {
      await initApiInst()
      return await actMappingApi.value.updateServicesProviders({
        inlineObject: { items }
      })
    } catch (e) {
      handleCommonErrors(e)
      return false
    }
  }

  const getCountriesIdsNames = async () => {
    let countriesIdsNames = []
    try {
      const cachedCountriesIdsNames = localStorage.getItem('countriesIdsNames')
      if (cachedCountriesIdsNames) {
        countriesIdsNames = JSON.parse(cachedCountriesIdsNames)
      } else {
        await initApiInst()
        countriesIdsNames = await actMappingApi.value.getAllCountriesIdsNames()
        localStorage.setItem('countriesIdsNames', JSON.stringify(countriesIdsNames))
      }
    } catch (e) {
      handleCommonErrors(e)
    }

    return countriesIdsNames
  }

  const updateCountriesProviders = async (items) => {
    try {
      await initApiInst()
      return await actMappingApi.value.updateCountriesProviders({
        inlineObject1: { items }
      })
    } catch (e) {
      handleCommonErrors(e)
      return false
    }
  }

  const getProviderNameById = (providers, providerId) => {
    const provider = providers.find((item) => item.value === Number(providerId))
    return provider ? provider.text : ''
  }

  return {
    getProviders,
    getServicesIdsNames,
    updateServicesProviders,
    getCountriesIdsNames,
    updateCountriesProviders,
    getProviderNameById
  }
}

<template>
  <b-row v-if="activationsData && activationsData.activations" class="activationsWrap">
    <b-col cols="12" class="my-3">
      <div class="header">Activations</div>
    </b-col>
    <b-col class="my-2">
      <div class="table-responsive mb-5">
        <table class="table">
          <thead>
            <tr>
              <th class="">Date</th>
              <th class="">Activation #</th>
              <th class="">Number</th>
              <th class="">Service/<br />Country</th>
              <th class="">Status/<br />Old Status</th>
              <th class="">Message</th>
              <th class="">Code</th>
              <th class="">Price</th>
              <th class="">User reason</th>
              <th class="">Support reason</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in activationsArr" :key="index" class="">
              <td>{{ format(new Date(row.created_at * 1000), 'yyyy-MM-dd HH:mm:ss') }}</td>
              <td @click="copyToCb($refs['activationId' + index][0], row.activation_id)" class="cursorPointer">
                <span :ref="`activationId${index}`">{{ row.activation_id }}</span>
              </td>
              <td @click="copyToCb($refs['phone' + index][0], row.phone)" class="cursorPointer">
                <span :ref="`phone${index}`">{{ row.phone }}</span>
              </td>
              <td>{{ row.service_id.toLowerCase() }}/<br />{{ row.country_id.toLowerCase() }}</td>
              <td>{{ row.status }}/<br />{{ row.old_status }}</td>
              <td>{{ row.sms_text }}</td>
              <td>{{ row.sms_code }}</td>
              <td>${{ Number(row.price) / 100 }}</td>
              <td v-if="row.rep_reason_id != null">
                <button
                  :disabled="loading"
                  class="btn btn-outline-primary refundBtn"
                  type="button"
                  @click="preSetActivationReason(row.activation_id, 'user', row.rep_reason_id)"
                >
                  <b-spinner v-if="loading" small variant="primary" />
                  <span v-else>{{ reasons[row.rep_reason_id - 1].name }}</span>
                </button>
              </td>
              <td v-else>
                <button
                  :disabled="loading"
                  class="btn btn-outline-primary refundBtn"
                  type="button"
                  @click="preSetActivationReason(row.activation_id, 'user')"
                >
                  <b-spinner v-if="loading" small variant="primary" />
                  <span v-else>Set reason</span>
                </button>
              </td>
              <td v-if="row.sup_reason_id != null">
                <button
                  :disabled="loading"
                  class="btn btn-outline-primary refundBtn"
                  type="button"
                  @click="preSetActivationReason(row.activation_id, 'support', row.sup_reason_id)"
                >
                  <b-spinner v-if="loading" small variant="primary" />
                  <span v-else>{{ reasons[row.sup_reason_id - 1].name }}</span>
                </button>
              </td>
              <td v-else>
                <button
                  :disabled="loading"
                  class="btn btn-outline-primary refundBtn"
                  type="button"
                  @click="preSetActivationReason(row.activation_id, 'support')"
                >
                  <b-spinner v-if="loading" small variant="primary" />
                  <span v-else>Set reason</span>
                </button>
              </td>
              <td class="">
                <div v-if="['smsReceived', 'retryReceived'].indexOf(row.status) !== -1" class="refundBl">
                  <button
                    :disabled="loading"
                    class="btn btn-outline-primary refundBtn"
                    type="button"
                    @click="preRefundActivation(row.activation_id)"
                  >
                    <b-spinner v-if="loading" small variant="primary" />
                    <span v-else>Refund</span>
                  </button>
                </div>
                <div v-else-if="['refunded', 'error'].indexOf(row.status) !== -1" class="refundBl">
                  <button class="btn btn-outline-primary refundBtn" type="button" disabled>
                    <b-spinner v-if="loading" small variant="primary" />
                    <span v-else>Refunded</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Paginator
          @next="showNextPage"
          @prev="showPrevPage"
          @selectPage="selectPage"
          :pagesCount="this.pagesCount"
          :currentPage="this.currentPage"
          :limit="this.limit"
          :itemsCount="this.itemsCount"
          class="pagination"
        />
      </div>
    </b-col>
  </b-row>
  <b-modal
    v-model="activationRefundModal"
    @keydown.enter="activationRefund"
    title="Are you sure?"
    class="questionModal"
    centered
    hide-footer
  >
    <div class="btnBl">
      <b-button @click="this.activationRefundModal = false" variant="light">Cancel</b-button>
      <b-button @click="activationRefund" variant="danger" class="confirmBtn">
        Refund activation #{{ this.activationId }}
      </b-button>
    </div>
  </b-modal>
  <b-modal
    v-model="selectActivationReasonModal"
    :title="'Select reason for activation #' + this.activationId"
    class="questionModal"
    centered
    hide-footer
  >
    <div class="d-block text-center">
      <div v-for="row in reasons" :key="row.id">
        <label>
          <input
            name="reason"
            type="radio"
            :value="row.id"
            class=""
            :checked="row.id === this.selectedReason"
            v-on:change="this.selectedReason = row.id"
          />
          {{ row.name }}
        </label>
      </div>
      <br /><br />
    </div>
    <div class="btnBl">
      <b-button @click="this.selectActivationReasonModal = false" variant="light">Cancel</b-button>
      <b-button @click="updateActivation" variant="danger" class="confirmBtn">
        Update reason for activation #{{ this.activationId }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { format } from 'date-fns'
import { useSearch } from '@/use/search'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import Paginator from '@/components/Paginator.vue'
import { feedbackReasons } from '@/constants/feedbackReasons'
import { useCopy } from '@/use/copy'

export default {
  components: {
    Paginator
  },
  data() {
    return {
      format,
      activationsArr: [],
      activationId: null,
      activationRefundModal: false,
      currentPage: 1,
      limit: 10,
      pagesCount: 1,
      itemsCount: null,
      reasonType: 'user',
      selectActivationReasonModal: false,
      selectedReason: null
    }
  },
  computed: {
    loading() {
      return this.$store.state.loaders.commonLoading
    },
    activationsData() {
      return this.$store.state.search.activationsData
    },
    searchAccount() {
      return this.$store.state.search.searchAccount
    },
    reasons() {
      return feedbackReasons
    }
  },
  setup() {
    const { getSearchData, getUserActivations } = useSearch()
    const { initApiInst, actActivationsApi } = useActAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    const { copyToCb } = useCopy()
    return { getSearchData, getUserActivations, initApiInst, actActivationsApi, handleCommonErrors, copyToCb }
  },
  watch: {
    activationsData(val) {
      if (val) {
        this.formActivationsArr()
      }
    }
  },
  mounted() {
    this.formActivationsArr()
  },
  methods: {
    formActivationsArr() {
      this.activationsArr = []
      if (this.activationsData !== null && this.activationsData.activations.length > 0) {
        for (const activation of this.activationsData.activations) {
          this.activationsArr.push(activation)
        }
        this.currentPage = this.activationsData.page
        this.limit = this.activationsData.limit
        this.pagesCount = this.activationsData.pages
        this.itemsCount = this.activationsData.total
      }
    },
    preRefundActivation(id) {
      this.activationRefundModal = true
      this.activationId = id
    },
    async activationRefund() {
      this.activationRefundModal = false
      this.$store.commit('loaders/setCommonLoading', true)
      try {
        await this.initApiInst()
        const resp = await this.actActivationsApi.refundActivation(this.activationId)
        if (resp && resp.result === 'success') {
          await this.getSearchData(this.searchAccount.user.fbuid)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Activation successfully refunded',
            timeout: 3000,
            type: 'success',
            name: 'success_refunded'
          })
        }
      } catch (e) {
        if (e.status === 409) {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Activation has been already refunded',
            timeout: 4000,
            name: 'already_refunded'
          })
        } else {
          this.handleCommonErrors(e)
        }
      } finally {
        this.$store.commit('loaders/setCommonLoading', false)
        this.clearData()
      }
    },
    clearData() {
      this.activationId = null
      this.reasonType = null
      this.activationRefundModal = false
      this.selectActivationReasonModal = false
    },
    showNextPage() {
      this.openPage(this.currentPage + 1)
    },
    showPrevPage() {
      this.openPage(this.currentPage - 1)
    },
    selectPage(id) {
      this.openPage(id)
    },
    async openPage(page) {
      this.$store.commit('loaders/setCommonLoading', true)
      this.$store.commit('search/setActivationsPage', page)
      await this.getUserActivations(this.searchAccount.user.id, page)
      this.clearData()
      this.$store.commit('loaders/setCommonLoading', false)
    },
    preSetActivationReason(activationId, reasonType, selectedReason = null) {
      this.activationId = activationId
      this.reasonType = reasonType
      this.selectActivationReasonModal = true
      this.selectedReason = Number(selectedReason)
    },
    async updateActivation() {
      if (this.selectedReason === 0) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Reason is empty! Please select a reason',
          timeout: 3000,
          name: 'empty_reason'
        })
        return
      }
      this.selectActivationReasonModal = false
      this.$store.commit('loaders/setCommonLoading', true)
      try {
        await this.initApiInst()
        const payload = {
          activation_id: this.activationId
        }
        if (this.reasonType === 'user') {
          payload.user_reason = Number(this.selectedReason)
        }
        if (this.reasonType === 'support') {
          payload.support_reason = Number(this.selectedReason)
        }

        const resp = await this.actActivationsApi.activationsFeedback({ rating: payload })
        if (resp && resp.result === 'success') {
          await this.getUserActivations(this.searchAccount.user.id, this.$store.state.search.activationsPage)
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.$store.commit('loaders/setCommonLoading', false)
        this.clearData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activationsWrap {
  margin-top: 30px;

  .header {
    font-size: 24px;
    border-bottom: 1px solid #717171;
  }
}
.cursorPointer {
  cursor: pointer;
}
</style>
